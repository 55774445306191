import React from 'react'
import Layout from '../components/layout'
import { css } from '@emotion/core'
import { rhythm } from '../utils/typography'
import SEO from '../components/seo'

export default ({ title, page }) => {
  return (
    <Layout>
      <SEO title={title} />
      <div
        css={css`
          margin: 0 auto;
          max-width: 960px;
        `}
      >
        <h1
          css={css`
            padding: ${rhythm(1)} 0 ${rhythm(1)};
          `}
          dangerouslySetInnerHTML={{
            __html: page.frontmatter.title,
          }}
        />
        <div
          css={css`
            text-align: left;
          `}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: page.html,
            }}
          />
        </div>
      </div>
    </Layout>
  )
}
